import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import DraftOrder from './components/DraftOrder';
import MockDraft from './components/MockDraft';
import ProspectRankings from './components/ProspectRankings';
import TradeMachine from './components/TradeMachine';
import Footer from './components/Footer.js';
import About from './components/About.js';
import PlayerProfile from './components/PlayerProfile';
import RosterAnalysis from './components/RosterAnalysis'; // Import the new component

function App() {
  return (
    <Router>
      <div>
        <div className="beta-version-banner">
          <p>Try our <b>Mock Draft Simulator</b></p>
        </div>
        <header>
          <div>
            <div className="logo">
              <Link to="/">the<span className="logo-tank">TANK</span></Link>
            </div>
          </div>
          <nav>
            <ul>
              <li><Link to="/mock">Draft Simulator</Link></li>
              <li><Link to="/draft-order">2025 Draft Order</Link></li>
              <li><Link to="/board">Power Rankings</Link></li>
              <li><Link to="/roster-analysis">Roster Analysis</Link></li> {/* New navigation link */}
            </ul>
          </nav>
        </header>
        <Switch>
          <Route path="/trade-machine" component={TradeMachine} />
          <Route path="/mock" component={MockDraft} />
          <Route path="/" exact component={MockDraft} />
          <Route path="/draft-order" component={DraftOrder} />
          <Route path="/board" component={ProspectRankings} />
          <Route path="/about-us" component={About} />
          <Route path="/player/:playerName" component={PlayerProfile} />
          <Route path="/roster-analysis" component={RosterAnalysis} /> {/* New route */}
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;