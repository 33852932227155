import React, { useEffect, useState } from 'react';
import axios from 'axios';

function RosterAnalysis() {
  const [rosterAnalysis, setRosterAnalysis] = useState({});
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState('');

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const rosterData = await axios.get(`${process.env.REACT_APP_BASEURL}/api/roster-data`);
        const uniqueTeams = [...new Set(rosterData.data.map(row => row[0]))].filter(Boolean);
        uniqueTeams.sort((a, b) => a.localeCompare(b));
        setTeams(uniqueTeams);
      } catch (err) {
        console.error('Error fetching teams:', err);
        setError(err.response ? err.response.data.error : err.message);
      }
    };
    fetchTeams();
  }, []);

  const calculateNeeds = (teamData, rosterData) => {
    const { agingPlayers, positionCount, expiring } = teamData;

    const agingPlayersByPosition = {};
    rosterData.forEach(player => {
      if (agingPlayers.includes(player.name)) {
        const position = player.position;
        agingPlayersByPosition[position] = (agingPlayersByPosition[position] || 0) + 1;
      }
    });

    const adjustedPositionCount = { ...positionCount };
    for (const position in agingPlayersByPosition) {
      const agingCount = agingPlayersByPosition[position];
      const reduction = Math.round(agingCount * 0.5);
      adjustedPositionCount[position] = Math.max(0, (positionCount[position] || 0) - reduction);
    }

    const needs = [];
    if ((adjustedPositionCount['Key Forward'] || 0) < 3) needs.push('Key Forward');
    if ((adjustedPositionCount['Forward'] || 0) < 4) needs.push('Forward');
    if ((adjustedPositionCount['Small Forward'] || 0) < 3) needs.push('Small Forward');
    if ((adjustedPositionCount['Ruck'] || 0) < 2) needs.push('Ruck');
    if ((adjustedPositionCount['Midfielder'] || 0) < 10 || agingPlayers.length > 2) needs.push('Midfielder');
    if ((adjustedPositionCount['Key Defender'] || 0) < 4) needs.push('Key Defender');

    console.log('Roster Data:', rosterData);
    rosterData.forEach(player => console.log('Player:', player.name, 'Age:', player.age));

    const totalAge = rosterData.reduce((sum, player) => sum + (player.age || 0), 0);
    const playerCount = rosterData.length;
    const averageAge = playerCount > 0 ? (totalAge / playerCount).toFixed(1) : 'N/A';

    console.log('Total Age:', totalAge, 'Player Count:', playerCount, 'Average Age:', averageAge);

    return { agingPlayers, positionCount, needs, expiring, averageAge };
  };

  const handleTeamChange = async (team) => {
  setSelectedTeam(team);
  setLoading(true);
  setError(null);
  try {
    let analysisData = {};
    let rosterData = [];

    if (team === '') {
      const analysisResponse = await axios.get(`${process.env.REACT_APP_BASEURL}/api/roster-analysis/all`);
      const rosterResponse = await axios.get(`${process.env.REACT_APP_BASEURL}/api/roster-data`);
      analysisData = analysisResponse.data;
      rosterData = rosterResponse.data;
    } else {
      const analysisResponse = await axios.get(`${process.env.REACT_APP_BASEURL}/api/roster-analysis/${team}`);
      const rosterResponse = await axios.get(`${process.env.REACT_APP_BASEURL}/api/roster-data`);
      analysisData = { [team]: analysisResponse.data };
      rosterData = rosterResponse.data.filter(row => row[0] === team);
      console.log('Filtered rosterData for', team, ':', rosterData);
    }

    const mappedRosterData = rosterData.map(row => ({
      team: row[0] || '',
      name: row[2] || '',
      age: parseInt(row[3] || '0', 10),
      position: row[4] || ''
    }));
    console.log('Mapped Roster Data:', mappedRosterData);

    const updatedAnalysis = {};
    for (const teamName in analysisData) {
      const teamRoster = mappedRosterData.filter(player => player.team === teamName);
      console.log(`Team: ${teamName}, Team Roster:`, teamRoster);
      updatedAnalysis[teamName] = calculateNeeds(analysisData[teamName], teamRoster);
    }

    setRosterAnalysis(updatedAnalysis);
  } catch (err) {
    console.error(`Error fetching analysis for ${team}:`, err);
    setError(err.response ? err.response.data.error : err.message);
  } finally {
    setLoading(false);
  }
};

  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="page-section-top">
      <h1>AFL Roster Analysis 2025</h1>
      <p>Analyze the current state of each team's roster, including aging players, position depth, contract expirations, and average list age. Data reflects active 2025 season rosters (retired and delisted players excluded).</p>
      <div className="roster-filter">
        <label htmlFor="team-select">Select Team: </label>
        <select
          id="team-select"
          value={selectedTeam}
          onChange={(e) => handleTeamChange(e.target.value)}
          disabled={loading}
        >
          <option value="">All Teams</option>
          {teams.map((team) => (
            <option key={team} value={team}>{team}</option>
          ))}
        </select>
        {loading && <div className="loader"></div>}
      </div>
      <div className="roster-analysis-container">
        {Object.keys(rosterAnalysis).length === 0 && !loading && (
          <div className="no-data">Select a team to view analysis.</div>
        )}
        {Object.keys(rosterAnalysis).length > 0 && (
          Object.keys(rosterAnalysis)
            .sort((a, b) => a.localeCompare(b))
            .map((team) => (
              <div key={team} className="roster-analysis-card">
                <h3>
                  <img
                    src={`/logos/${team}.png`}
                    alt={`${team} logo`}
                    style={{ width: '24px', marginRight: '10px' }}
                  />
                  {team}
                </h3>
                {rosterAnalysis[team].error ? (
                  <div className="error">{rosterAnalysis[team].error}</div>
                ) : (
                  <table className="analysis-table">
                    <tbody>
                      <tr>
                        <td><strong>Aging Players (&gt;30):</strong></td>
                        <td>{rosterAnalysis[team].agingPlayers.join(', ') || 'None'}</td>
                      </tr>
                      <tr>
                        <td><strong>Position Depth:</strong></td>
                        <td>
                          {Object.entries(rosterAnalysis[team].positionCount)
                            .map(([pos, count]) => `${pos}: ${count}`)
                            .join(', ')}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Needs:</strong></td>
                        <td>{rosterAnalysis[team].needs.join(', ') || 'None'}</td>
                      </tr>
                      <tr>
                        <td><strong>Players Expiring 2025:</strong></td>
                        <td>{rosterAnalysis[team].expiring}</td>
                      </tr>
                      <tr>
                        <td><strong>Average List Age:</strong></td>
                        <td>{rosterAnalysis[team].averageAge} years</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            ))
        )}
      </div>
    </div>
  );
}

export default RosterAnalysis;